@import "../styleVariables";
.Container {
    background: whitesmoke;
    color: #1b1e21;
    padding: 30px 0px 16px 0px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 10px;
}

.Container .Wrap {
    text-align: center;
    background: $dark-blue;
    border-radius: 50px;
    color: white;
    margin: 10px 0px;
}


.Container h2 {
    margin: 20px;
}

.Container h3 {
    margin: 20px;
}

.Critical {

    background: #fe7f7f;
    padding: 4px;
}

.Advice {

    background: #fefe7f;
}

.Optimal {

    background: #9c9;
}


.ToolTip {
    font-family: Roboto;
    font-size: 16px;
    margin-top: -25px;
    background: #060000b3;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 1px 1px;
    border:1px solid black;
    min-width: 50px;
}
.ButtonEnter{

    background: $light-green;
    color:$dark-blue;
    border:none;
    font-size: 1em;
    min-height: 35px;
    min-width: 100px;
    margin: 25px 0px 25px 0px;
    border-radius: 30px;
    padding:0px 20px 0 20px;
}
.ButtonEnter :hover{
    background: $light-grey;
    color:$light-blue;
}
.ButtonReset{

    background: $light-blue;
    color:$dark-blue;
    border:none;
    font-size: 1em;
    min-height: 35px;
    min-width: 100px;
    margin: 25px 0px 25px 0px;
    border-radius: 30px;
    padding:0px 20px 0 20px;
}
.ButtonReset :active{
    background: $light-grey;
    color:$light-blue;
}
.ReadingTitle{
    text-align: center;
    margin: 20px 0 60px 0px;
    background: #0d0831;
    color: aliceblue;
    width: min-content;
    border-radius: 0px 10px 10px 0;
    padding: 0 16px 6px 16px;

}
.ValueIndicatorAdvice{

    texta-align: center;
    border-radius: 10px;
    background: #fcfcbb;
    //background: rgb(254, 254, 127) none repeat scroll 0% 0%;
    min-width: 45px;
    display: inline-table;
    color: black;
    font-size: 1em;
    /*padding: 5px 10px 5px 10px;*/
    -webkit-box-shadow: 0px 0px 4px 2px rgba(245,245,12,1);
    -moz-box-shadow: 0px 0px 4px 2px rgba(245,245,12,1);
    box-shadow: 0px 0px 4px 2px rgba(245,245,12,1);
}
.ValueIndicatorCritical{

    texta-align: center;
    border-radius: 10px;
    background: #fed7d7;
   // background: #fe7f7f none repeat scroll 0% 0%;
    min-width: 45px;
    display: inline-table;
    color: black;
    font-size: 1em;
   /* padding: 5px 10px 5px 10px;*/
    -webkit-box-shadow: 0px 0px 4px 2px rgba(247,10,10,1);
    -moz-box-shadow: 0px 0px 4px 2px rgba(247,10,10,1);
    box-shadow: 0px 0px 4px 2px rgba(247,10,10,1);
}
.IndicatorRow{
    margin-bottom: 45px;
}