

/* 1. Enable smooth scrolling */
html {
    scroll-behavior: smooth;
}

/* 2. Make nav sticky */
main > nav {
    position: sticky;
    top: 2rem;
    align-self: start;
}

/* 3. ScrollSpy active styles (see JS tab for activation) */
.section-nav li.active > a {
    color: #333;
    font-weight: bold;
    font-size: inherit;
}

/* Sidebar Navigation */
.section-nav {
    padding-left: 0;
    border-right: 1px solid #bbbbbd;
width:90%;
}

.section-nav a {
    text-decoration: none;
    display: block;
    padding: .125rem 0;
    color: #ccc;
    transition: all 50ms ease-in-out; /* 💡 This small transition makes setting of the active state smooth */
font-size: medium;
}

.section-nav a:hover,
.section-nav a:focus {
    color: #666;
}

/** Poor man's reset **/
* {
    box-sizing: border-box;
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
li {
    margin-left: 1rem;
}

h1 {
    font-weight: 300;
}

/** page layout **/
main {
    display: grid;
    grid-template-columns: 15em 1fr; /* todo: Breakpoints for small devices*/
    max-width: 100em;
    width: 100%;
    margin: 0 auto;
}

/** enlarge the sections for this demo, so that we have a long scrollable page **/
section {
    padding-bottom: 6rem;
}

@media (max-width: 900px) {
main{
    grid-template-columns: 10em 1fr; /* todo: Breakpoints for small devices*/
}
    .section-nav {

        width:90%;
    }
}