@import "variables";
// BODY / MAIN
html {
  overflow-x: unset;
}
ul {
  list-style-type: none;
}
.App {
  text-align: center;
overflow: hidden;
  background-color: black;
  padding: 5px;
}

body {
  margin: 0;
  font-family: akkurta,Inconsolata, monospace, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #08293f;
  overflow-x: hidden;


}

.text-on-primary {
  color: $primary-match-4;
  font-size: 1em;
  //text-shadow: $text-shadow;
}

code {
  font-family: akkurta,Inconsolata,monospace, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

// NAV-BAR

.navbar .navbar-expand-md .navbar-light {
  background: #0D44F0;
}

#navbar {
  top: 0;
  transition: top 0.4s; /* Transition effect when sliding down (and up) */
  width: 100%;
}

.navbar-toggler {
  background-color: #e89e00;
  color: white;
  margin-right: 20px;
}

.navbar-brand:hover {
  //background-color: black;
  //animation: App-logo-spin infinite 2.5s cubic-bezier(.46,-0.56,.27,1.55);
}

.nav-active {
  font-size: 1em;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #e89e00;
  //overflow: auto;
}
.remove-decoration{
  text-decoration: none;
  color: white;
}
.remove-decoration:hover{
  text-decoration: none;
  color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #3b6aac;
  font-size: 1.2em;
}

h1.site-title {
  font-size: $dynamic-font-site-title;
  font-weight: bold;
  text-shadow: $text-shadow;
  color: aliceblue;
  font-family: akkurta,Inconsolata,monospace, 'Lato', sans-serif;
  position: relative;
  text-align: left;
  padding-left: 20px;
  margin-top: 40px;
  // transform: translate(-50%, -110%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  background-color: black;
  position: fixed;
  top: 0;
  width: 30%;
  z-index: 10;
}

div.navbar-collapse {
  background: black;
}

// Font of nav bar
.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 1em;
  font-family: akkurta,Inconsolata,monospace, "Roboto", sans-serif;
}

.navbar-light .navbar-brand {
  color: $font-color-over-primary;
  font-size: 1em;
}

a.nav-link {
  color: $font-color-over-primary;
}

.navbar-toggler-icon {
  color: $font-color-over-primary;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .4);
}

// HEADER
.App-header {
  background-color: $primaryColour;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 841px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: $font-transform;
  color: white;
  font-family: akkurta,Inconsolata,monospace, 'Roboto', sans-serif;
  max-height: 100%;
}

.react-parallax-bgimage {
  height: 1444px !important;
  overflow: inherit;
  margin-top: 400px;
}

.react-parallax-content {
  margin-top: 100px;
}

// Introduction
.img-parent {
  height: auto;
  padding: 5% 0 0 0;
  //transform:translate(10%, -10%);

}


.intro-paragraph {
  text-align: left;
  padding: 20px;
  max-width: 95%;
  line-height: 1.5;
  font-size: 1.3em;
}

.about-paragraph {
  text-align: center;
  padding: 20px;
  max-width: 95%;
  line-height: 1.5;
  font-size: smaller;
}

.tab-title {
  font-size: calc(16px + 1vmin);
  color: black;
}

.nav-tabs {
  //border-bottom: 0 solid #dee2e6;
  background-color: $form-grey;
}

.card-body {
  text-align: center;
}

.tab-title .active {
  color: black;
}

.card-title {
  color: black;
  font-weight: bolder;

}

.card-text {

  color: black;
  font-size: 20px;
  line-height: 1.5em;
 // max-height: 3.2em;

}

.modal-text {
  color: $font-color-over-white;
  text-align: center;
  //margin: fill;
  font-size: 2.5vw;
  // min-width: 34px;
}

p.modal-content {
  text-align: left;
}

.modal-content {
  background: black;
  color: white;
  text-align: left;
}

.skill-icons {
  display: block;
  margin: 15px auto;
  padding: 5%;
  max-width: 75%;
}


.bootstrap-icon {

}

.skills-tab-container {
  margin: 0;
  padding: 20px;
  max-width: 100%;
  color: white;
  // border-radius: 0 0 5px 5px;
}
.icon-paragraph{
  font-size: 1em;
  color: #1d81c4;
}
.tabs-wrapper {
  margin: auto;
  width: 100%;
  // border: 3px solid $primary-match-3;
  padding: 0;
}

.tab-content .active {
  border-radius: 0 0 5px 5px;
}

.icon-container {
  background: black;
  margin: 6px;
}

.certification-carousel-wrapper {
  text-align: center;
  max-width: 600px;
  //  width:100%;
}
.carousel-indicators li{
  background-color: black;
  height: 5px;
}
.carousel-control-prev-icon, .carousel-control-next-icon{
  background-color: black;
  height: 30px;
}
.cert-image-wrapper {
  margin: 0 auto;
}

.certification-image {
  padding: 50px 0 0 0;
  cursor: pointer;
  max-width: calc(100px + 16vmin);
}

.certificates {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modal-footer {
  background-color: black;
}

.nav-item:hover {
  cursor: pointer;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #8e2625;
}

.skills-subhead {
  font-size: $dynamic-font-subheading-1;
  margin-bottom: 40px;
}

button.skills-button {
  width: 100%;
  text-align: center;
  background-color: $primary-match-2;
  border: $primary-match-3;
}

button.skills-button:hover {
  font-size: 2em;
}

.button-projects {
  background-color: $primary-match-2;
  border: $primary-match-2;
}

.button-projects:hover {
  background-color: #28A745;
  border: $primary-match-1;
  font-size: 1.1em;
  //width: 8em;
  //height: 4em;
  box-shadow: 1px 1px 10px $primaryColour;
}

.button-wrapper {
  display: inline-block;
  margin: 26px 0 61px 0;
}

ul.nav.nav-tabs {
  background-color: #e89e00;
  border-radius: 5px 5px 0 0;
}

.rounded-circle {
  //box-shadow: 2px 2px 25px #14293c;
  max-width: 72%;
}

.tab-content .active {
  background-color: black;
}

// CAROUSEL

div.alice-carousel {
  //max-height: 45rem;
  background: black;

}
div.alice-carousel__wrapper{
  width: 100%;

}
li.alice-carousel__stage-item {
  //width: 50%;
  //padding: 0 100px 0 0;
}

ul.alice-carousel__dots {
  margin: .3em 0 0;
}

li.alice-carousel__dots-item {
  width: 20px;
  height: 20px;
  margin-top: 1em;
}

li.alice-carousel__dots-item.__active {
  background: #808080;
}

div.alice-carousel__next-btn-wrapper {
  text-align: left;
}

div.alice-carousel__play-btn-wrapper {
  padding: 10px;
  width: 52px;
  height: 52px;
  border-radius: 52%;
  background: rgba(255, 255, 255, 0.6);
}

div.alice-carousel__play-btn-item::before {
  height: 30px;
  left: 5px;
}

.carousel-button {
  transform: translate(0%, 0%);
}

.project-card {
  max-width: 90%;
  margin: 10px 18px 2px 14px;
  //text-shadow: $text-shadow;

}
.card-body-override{
background-color:  black;
}

.hoverWrapper:hover .card-img{
  box-shadow: 1px 1px 10px $primary-match-2;
  opacity: 0.2;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  color: white;

}
.hoverWrapper:hover #hoverShow1 {
  display: block;
  cursor: pointer;
}

.hoverWrapper #hoverShow1 {
  display: none;
  position: absolute;
  color: white;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;

}
.align{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width:100%;

}
h1.projects-title {
  color: $primary-match-4;
  margin: 150px 0 40px 0;
  font-size: $dynamic-font-subheading;
  font-family: akkurta,Inconsolata,monospace, 'Lato', sans-serif;

}
.slide{
  animation: slide 1.5s ease-in forwards;
}
@keyframes slide  {
  0% {
    transform: translateX(-140%);
  }
  100%{
    transform: translateX(0%);
  }
}
.projects-back-btn{
  margin: 0 0 60px 90%;
  justify-content: flex-end;

}
h1.experience-title {
  margin: 150px 0 150px 0;
  color: $primary-match-4;
  font-size: $dynamic-font-subheading;
  font-family: akkurta,Inconsolata,monospace, 'Lato', sans-serif;
}

.projects-container {

  max-width: 100%;
  padding: 58px 5px 100px 5px;

}

.projects-paragraph {
  font-size: larger;
  margin-bottom: 60px;
  color: $primary-match-4;
}

// CONTACT FORM
.contact-title {
  color: $primary-match-4;
  margin: 40px 0 0 20px;;
  font-size: $dynamic-font-subheading;
  font-family: akkurta,Inconsolata,monospace, 'Lato', sans-serif;
  text-align: left;
}

.more-info-title {
  color: $primary-match-4;
  margin: 40px 0 0 20px;
  font-size: $dynamic-font-subheading;
  font-family: akkurta,Inconsolata,monospace, 'Lato', sans-serif;
  text-align: left;
}

.form-container {
  max-width: 95%;
  margin: 100px auto 0 auto;
}

.form-title {
  margin-top: 20px;
  font-size: 3rem;
  font-family: akkurta,Inconsolata,monospace, 'Roboto Slab', serif;
}

.form-wrapper {
  background-color: black;
  padding: 20px 20px 20px 20px;
  //border: 1px solid #aaa;
  border-radius: 4px;
  color: $primary-match-4;
  font-size: large;
  width: 100%;
  display: block;
  margin: 40px auto;
}

.form-wrapper:hover {
 // box-shadow: 1px 1px 20px $primaryColour;
}

.form-text-left {
  text-align: left;
}

.form-group {
  margin-bottom: 2em;
}

// TEST AREA
.test-area-wrapper {
  background-color: #dee2e6;
  padding: 20px;
}

.project-icons {
  text-align: center;
  color: white;
}

// FOOTER

.footer-wrapper {
  background-color: black;
  border-top: 1px solid #8e2625;
  padding: 2em;
  color: $primary-match-4;
}

.footer-links {
  padding: 0 20px 0 20px;
  margin-bottom: 10px;
  text-align: left;
}

.icons-wrapper {
  text-align: center;
  margin: 0 30px 0 30px;
  padding: 27px;
}

.footer-icons {
  margin: 50px 0 0 10px;
  height: 64px;
}

.footer-copyright {
  margin-top: 30px;
  margin-bottom: 10px;
}

.footer-paragraph {
  text-align: left;
  font-size: smaller;
}

a.footer-links:hover {
  margin: 10px;
  height: 8vmin;
  width: 8vmin;
}

.more-info-container {
 
  padding: 84px 1px 0 0;
  max-width: 100%;
}

// Single project

.modal-dialog.single-project-modal {
  max-width: 98%;
  text-align: left;
  font-size: 1.1em;
}

/*.modal-dialog {
  max-width: 98%;

}*/

h5.modal-title {
  margin-left: auto;
  text-align: center;
}

.project-modal-img {
  padding: 1px;
  border: $primaryColour solid 1px;
  margin: 0 0 30px 0;
}

p.project-modal-text {
  text-align: center;
}

// PROJECTS
.project-inner-wrapper {
  padding: 0 10px 40px 10px;
}

.btn.btn-danger {
  font-size: .8em;
}

// Table
.employee-table {
  margin: 70px 0 50px 0;
  font-size: 1em;
  overflow:inherit;
  text-align: center;
}

.sort-table.btn {
  font-size: 1em;
}

.align-left {
  text-align: left;
}

.rotate-device-advice {
  background-color: $primary-match-3;

  padding: 5px 5px 5px 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-radius: 10px;
  font-size: .9em;
  width: 100%;
  z-index: 10;
  color:
          black;
}

.rotate-device-x {
  font-size: small;
  margin: 0;
}

.rotate-device-paragraph {
  margin: 0;
}

.rotate-device-advice:hover {
  cursor: pointer;
}

// Table

.item-enter {
  opacity: 0;
}

.item-enter-active {
  transform: scaleX(1) translateY(-100px) translateX(-100px);
  transition: all 700ms ease-in-out
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 1;
  transform: scaleX(.001) translateY(100px) translateX(40px);
  transition: all 800ms ease-in-out
}

a.sort-table {
  cursor: pointer;
}

.ml-auto, .mx-auto {
  margin-left: initial;
}

// ADD EMPLOYEE FORM

.add-employee-form {
  border-radius: 5px;
  background-color: $form-grey;
  padding: 20px;
  // text-align:left;
  max-width: 700px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: black;
}

// Targets the background in the radio buttons
.radio-group {
  padding: 10px;
  background-color: $form-grey;
}

h1.probe-title {
  margin: 10%;
}

.img404 {
  margin: 10%
}

.iframe-blog {
  margin-left: 1px;
  height: 1596px;
  margin-top: -920px;
  width: 100%;
}

#container {
border-radius: 20px;
  overflow: hidden;
  margin: 15px auto;
  width: 100%;
  max-width: 680px;

}
.dropdown-1{
  background-color:
          #000;
  border-color:
          #8e2625;
}
.dropdown-2{
  background-color:
          #000;
  border-color:
          #8e2625;

}
.dropdown-1:hover{
  background: #c68b0d;
  box-shadow: 1px 1px 20px $primaryColour;
}

.dropdown-2:hover{
  background: #c68b0d;
  box-shadow: 1px 1px 20px $primaryColour;
}
.dropdown-1:focus{
  background: #8e2625;
}
.dropdown-2:focus{
  background: #8e2625;
}
.alert-dark{
  background-color:
          #040404;
  border-color:
          #000;
}
.alert-dark:hover {

}
.number{
  color: white;
}
.iframe-container{

    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
  margin-bottom: 160px;
  }

  .iframe-container iframe,
  .iframe-container object,
  .iframe-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

// MEDIA QUERIES
@media only screen and (max-width: 900px) {

  .project-card{
    margin: 7px 9px 5px 15px;
    max-width: 90%;
  }
}
@media only screen and (max-width: 780px) {
  .react-parallax-content{
    margin-top: -60px;
  }
  h1.site-title {
    text-align: center;
  }
  .about-paragraph {
    text-align: center;

  }
  .rounded-circle {
    max-width: 50%;
  }
  .form-container p {
    text-align: center;
  }
  .form-wrapper {
    padding: 50px 6px 40px 6px;
  }
  .form-container {
    max-width: 100%;
  }
}


@media only screen and (max-width: 992px) {
  .react-parallax-content{
    //margin-top: -120;
  }
  .skills-subhead {
    margin-bottom: 60px;
  }
  .final-col {
    margin-top: 60px;
  }
  h1.more-info-title {
    text-align: center;
  }
  .contact-title {
    text-align: center;
  }
  .icons-wrapper {
    text-align: center;
    margin: 60px 0 60px 0;
  }
  .certification-image {
    max-width: calc(200px + 16vmin);
  }
  .footer-paragraph {
    text-align: center;
  }
  .about-paragraph {
    text-align: center;
  }
  .intro-paragraph {
    text-align: center;
  }
  .contact-title {
    margin: 120px 0 0 0;
  }
}

@media only screen and (max-width: 992px) and (min-width: 700px) {
  .form-wrapper {
    padding: 50px 82px 40px 82px;
  }
}
@media only screen and (max-width: 600px) {

.icons-wrapper{
  padding: 20px 0 20px 0;
}
  .skills-tab-container{
    padding: 20px 0 20px 0;
  }
}
