@import "styleVariables";
.SensorsContainer{
    background-color: #000000;
    color: white;
    max-width: 100%;
    margin-top: 100px;
    text-align: left;
    padding: 0%;
    font-size: calc(10px + 1vmin);
}
.ProjectContainer{
    font-family: $body-font;
    background: $dark-blue;
    padding:10px;
    max-width:100%;
    color:whitesmoke;
    border-radius:15px;

    margin-bottom: 40px;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
}
.Title{
    margin:30px 0px 45px 0px;
    padding: 10px
}
.SecondaryTitle{
    margin:30px 0px 30px 0px;
    padding: 10px
}
.ProjectContainer h1, h2, h3, h4, h5, h6{
    font-family: $title-font
}

.h5{
    text-align: center;
    margin: 40px auto;
}
.SlidersWrap{
    background: #151134;
    margin: 20px auto;
    border-radius: 10px;
    color:#eee;
    max-width: 600px;
}
.StatusWrapper{
    background:
            white;
    color:
            black;
    border-radius: 20px;
    padding: 16px 0px 0px 0px;
    text-align: center;
    max-width: $demo-width;
    margin: 50px auto;
    border: 1px solid
    #550909;
}
.AccordionContainer{
    padding: 0px;
    border-radius: 20px;
    background: $dark-blue;
    color:whitesmoke;
}
.BarsWrapper{
    background: $dark-blue;
    color: black;
    border-radius: 0 0 20px 20px;
    padding: 40px 0px 20px 0px;
    text-align: center;
    max-width: $demo-width;
    margin: auto;
}
.ToggleButton{
    background: #1b1e21;
    color: grey;
}
.TestButton{
    background: $light-green;
    margin-top: 20px;
    border-radius: 100px;
    padding:10px;
    color: $dark-blue;
}

.SlidersContainer{
    margin: 0px 45%;

}
.GraphHead{
    font-size: 2em;
}
.GraphTitle{
    font-size: 1.5em;
    margin: 40px 10px 20px 10px
}
.GraphSub{
    font-size: 1em;
    margin: 30px 10px 33px 10px;
}
.StickyElement{
    position: fixed;
    top: 0px;
    width: 97%;
    z-index: 100;
    transition: top 2s;

}
.StickyElement + .StickyContainer {
    /* margin-top: 1000px;*/
}
.AddHeight{
    padding-top: 57px;
}
.TabContainer{
    border-radius:  0 20px 0 0;
}
.TabBody{
    border-radius:  200px 200px 200px 200px;
}

@media (max-width: 991px)  {

    .AddHeight{
        padding-top: 57px;
    }
    .StickyElement{

        width: 97%;

    }
}
@media (max-width: 575px)  {
    .StickyElement{
        max-width: 100%;
        width: 93%;
        margin-left: 0px;
    }

    .AddHeight{
        padding-top: 97px;
    }

}
.ButtonEnter{

    background: $light-green;
    color:$dark-blue;
    border:none;
    font-size: 1em;
    min-height: 35px;
    min-width: 100px;
    margin: 25px 0px 25px 0px;
    border-radius: 30px;
    padding:0px 20px 0 20px;
}
.ButtonEnter :hover{
    background: $light-grey;
    color:$light-blue;
}
.ButtonReset{

    background: $light-blue;
    color:$dark-blue;
    border:none;
    font-size: 1em;
    min-height: 35px;
    min-width: 100px;
    margin: 25px 0px 25px 0px;
    border-radius: 30px;
    padding:0px 20px 0 20px;
}
.ButtonReset :active{
    background: $light-grey;
    color:$light-blue;
}

.SectionText{
    max-width:600px;
    padding: 16px 0px 35px 0px;
    text-align: center;

    margin: 0px auto;

}
.IframeForm{
    margin-left: 1px;
    height: 1596px;
    margin-top: -919px;
    width: 100%;
}