@import "../styleVariables.scss";
.Wrap{
    background: whitesmoke;
    padding:10px;
    max-width:$demo-width;
   /* width: max-content; */
    color:black;
    border-radius:15px;
    border:1px solid #550909;
    margin-bottom: 40px;
    position: -webkit-sticky; /* Safari & IE */
    position:sticky;
    top:0;
    width:100%;
    z-index:100;
    margin: auto;

}
.Pick{


}
.Fields{
    width: 80%;
    margin:0px 10px 0 10px;
}
.Title{
    text-align: center;

}
.Button{

    background: $light-green;
    color:$dark-blue;
    border:none;
    height: 35px;
    min-width: 100px;
    border-radius: 30px;
    padding:0px 20px 0 20px;
}
.Button :hover{
    background: $light-grey;
    color:$light-blue;
}

@media (max-width: 575px)  {
    .Fields{
        width: 86%;
        margin:10px 10px 0 10px;
    }
}
@media (max-width: 766px)  {
    .Fields{
        width: 80%;
        margin:10px 10px 0 10px;
    }
}