.project-template-title {
  margin: 10% 0 100px 0;
  text-align: center;
}

.reading-box {
  text-align: center;
  padding: 10px;
}

.sensors-container {
  background-color: #000000;
  color: white;
  max-width: 100%;
  margin-top: 100px;
  text-align: left;
  padding: 4%;
  font-size: calc(10px + 1vmin);
}
.readings-container{
  margin-top: 60px;
}
* {
  box-sizing: border-box;
}

.flex-grid {
  margin: 0 0 20px 0;
}

.info-icon {
  max-width: 48px;
}
.divider{
  color: red;
  border-radius: 15px;
  border: 1px solid
  #550909;
  margin-top:60px;
  margin-bottom: 60px;
  width:100%;

}
.border-box{
  border: 1px solid
  #550909;
  margin-top:60px;
  margin-bottom: 60px;
  padding:30px;
}
.temp-icon{
  display: block;
  margin-left: auto;
  margin-right: auto;
  //  width: 90%;
  padding-bottom: 40px;
  padding-top: 40px;

  max-height: 200px;
}
.badge-secondary-override {
  color: #000;
  background-color: #fdfdfd;
  border-radius: 4px;
  margin-left: 10px;
  margin-top: 0;
  padding: 10px 10px 11px 10px;

  border: 1px solid black
}
.sliders-container{
  background:
          #130f1e;
  padding: 40px;
  border-radius: 15px;
  border: 1px solid
  #550909
}
.row-margin{
  margin-top: 50px;
  margin-bottom: 150px;
}
.fish-image{
  display: block;
  margin-left: auto;
  margin-right: auto;

  width:90%;

}
.status-wrapper {
  background: black;
  color: whitesmoke;
  border-radius: 5px;
  padding: 30px 0 0 0;
  text-align: center;
  max-width: 650px;
  margin: auto;
  border:1px solid #550909
}

.red-alert {
  background-color: #c8031f;
  cursor: pointer;
  margin-bottom: 2px;
  padding: 10px 10px 10px 10px;
  -webkit-box-shadow: 0px 0px 10px 1px #f50000;
  -moz-box-shadow: 0px 0px 10px 1px #f50000;
  box-shadow: 0px 0px 10px 1px #f50000;
}

.yellow-alert {
  background-color: #d3b126;
  cursor: pointer;
  margin-bottom: 2px;
  padding: 6px 6px 6px 6px;
}

.green-alert {
  background-color: #187316;
  cursor: pointer;
  padding: 6px 6px 6px 6px;
  margin-bottom: 2px;

}

/*
p.alert {
  margin: 0;
}*/
