@font-face {
  font-family: "Open Sans";
  src: url("./Assets/fonts/OpenSans-Regular.ttf");
 src:
             url("./Assets/fonts/opensans-regular-webfont.woff") format("woff");

}
$dark-blue: #0D0831;
$mid-blue: #214483;
$light-blue: #64B6FF;
$light-green: #C0E77F;
$white: white;
$light-grey: #E3E3E3;
$dark-grey: #C4C4C4;
$black: black;
$demo-width: 680px;
$title-font: Open Sans;
$body-font: Roboto;
