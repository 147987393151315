@import "../styleVariables.scss";

.Container{
    background: whitesmoke;
    color: #1b1e21;
    text-align: left;
    padding:50px 25px 50px 16px;
    font-family: $body-font;
    margin: 0 0 0 0;
    border-radius: 20px;
}
.Flex{
    display: flex;
}
.SubFlex{
    display: flex;
    margin: 5px 20px 5px 20px;
}
.Icon{
    margin-left: auto;
   // margin-top: auto;
    transition: transform 1000ms ease;
}

.NoRotate{
    transform: rotate(0deg);
}
.Content{
    margin-left: auto;
    transition:  max-height .5s ease;
    overflow: scroll;

}
.SubContent{
    margin-left: auto;
    transition: height .7s ease;
    overflow: hidden;
}
.Rotate{
    transform: rotate(270deg);
}
.Button{
    border: 0px;
    background: #eeeeee;
    width: 100%;
    max-height: 50px;
    text-align: left;

}
.SubButton{
    border: 0px;
    background: #eeeeee;
    width: 100%;
    text-align: left;

}

.SubButton:hover,  .active{
    font-weight: bold;
    background: #ccc;

}
.Button:hover,  .active{
    font-weight: bold;
    background: #ccc;

}
.IntroHeader{
    margin-top:30px;
}

@media (max-width: 575px)  {

}