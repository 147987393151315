@import "../styleVariables";
.Container{
    padding:16px 16px 0px 16px;
    border-radius:8px;
}

.Container h4{
    text-transform: capitalize;
    text-align: center;
}

.FishImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
padding:0px;
    width:70%;
    max-width: 400px;

}
.OptimalWrapper{
    background: $white;
    padding: 0px;
    color: #372727;

}

.Background{

    background: $light-green;
    color:$dark-blue;
    border:none;
    font-size: 1em;
    min-height: 35px;
    min-width: 100px;
    margin: 25px 0px 25px 0px;
    border-radius: 30px;
    padding:0px 20px 0 20px;
}
.Background :hover{
    background: $light-grey;
    color:$light-blue;
}